const helpers = require.context("./helpers", true, /.*\.(js)$/);
helpers.keys().forEach(function(key){
    helpers(key);
});

;(function () {
    'use strict';

    window.fuckIE = !!window.MSInputMethodContext && !!document.documentMode;

    // Include js-modules
    const req = require.context("./modules", true, /_[^_].*\.(js)$/);
    req.keys().forEach(function(key){
        req(key);
    });

})();
