import Form from "../classes/Form";
import {clear} from "../helpers/formClear";

$(function () {
    $('select, input, textarea').on('input change', function () {
        if ($(this).hasClass('error')) {
            $(this).removeClass('error');
            $(this).siblings('label.error').remove();
        }
    });

    $('[data-form]').submit(function (e) {
        e.preventDefault();

        var form = $(this),
        obj = Form.get('#' + $(this).attr('id'));

        if (!obj.isValid())
            return false;

        $.ajax({
            type: 'POST',
            url: '/ajax/form/send/',
            data: form.serialize(),
            dataType: 'json',
            beforeSend: () => {
                clear(form);
                $('.successSend').show();
            },
            complete: () => {
            },
            success: (data) => {
            }
        });
    });
});
