class Registry {
    static get(slug, selector) {
        this.checkInstances();

        return this.instances[selector] ? this.instances[selector][slug] : null;
    }

    static set(slug, selector, instance) {
        this.checkInstances();

        this.instances[selector] = this.instances[selector] || {};
        this.instances[selector][slug] = instance;

        return this;
    }

    static forget(slug, selector) {
        this.checkInstances();

        delete this.instances[selector][slug];

        return this;
    }

    static checkInstances() {
        if (typeof this.instances == 'undefined')
            this.instances = {};
    }
}

export default Registry;