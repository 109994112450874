import Validate from '../plugins/Validate';
import Registry from "../plugins/Registry";

class Form {
    static get(selector) {

        var instance = Registry.get('Form', selector);
        if (instance)
            return instance;

        instance = new Form(selector);
        Registry.set('Form', selector, instance);

        return instance;

    }

    constructor(selector) {

        this.selector = selector;

        if (typeof selector == 'string') {
            this.form = $(this.selector);
        } else {
            this.form = this.selector;
        }

        return this;
    }

    getValues() {
        this.values = {};

        var self = this;
        this.form.find('[name]').each(function () {
            self.values[$(this).attr('name')] = $(this).val();
        });

        return this.values;
    }

    isValid() {
        this.errors = {};
        this.values = this.getValues();
        var msg,
            i = 0,
            value,
            element;

        if (this.form.find('[name="email"]').length) {
            value = this.values.email;


            if (value.length && !Validate.email(value)) {
                msg = 'Укажите корректный Email';
                this.errors.email = msg;
                this.showError('email', msg);
            } else {
                this.hideError('email');
            }
        }


        for (i = 0; i < this.form.find('[required]').length; i++) {
            element = this.form.find('[required]').eq(i);

            if (element.val() === '') {
                element.addClass('error');
                msg = 'Поле обязательно для заполнения';
                this.errors[element.attr('name')] = msg;
                this.showError(element.attr('name'), msg);
            } else {
                if (! this.errors[element.attr('name')])
                    this.hideError(element.attr('name'));
            }
        }


        for (i = 0; i < this.form.find('select').length; i++) {
            element = this.form.find('select').eq(i);
            if (!(element.parent().css('display') === 'none')) {
                if ((!element.val()) && (element.val() !== [])) {
                    element.parent().find('.select-head').addClass('error');
                    msg = 'Выберите одно из полей';
                    this.showError(element.attr('name'), msg);
                    this.errors[element.attr('name')] = msg;
                }
                else {
                    this.hideError(element.attr('name'))
                }
            }
        }

        return Object.keys(this.errors).length === 0;
    }

    url(value) {
        if (!value)
            return this.uri;

        this.uri = value;

        return this;
    }

    clear() {
        this.form.find('input:not([type="hidden"]):not([type="submit"]), select, textarea').val('');
        this.form.find('input, select, textarea').each(function () {
            $(this).next().text('');
        });
    }

    hideErrors() {
        for (var name in this.values)
            this.hideError(name)
    }

    hideError(name) {
        this.form.find('[name="' + name + '"]').removeClass('error');
        this.form.find('label[for="' + name + '"].error').remove();
    }

    showError(name, msg) {
        this.form.find('[name="' + name + '"]').addClass('error');
        if (this.form.find('[name="' + name + '"]').next('label.error[for="' + name + '"]').length)
            this.form.find('[name="' + name + '"]').next().html(msg);
        else
            this.form.find('[name="' + name + '"]').after('<label for="' + name + '" generated="true" class="error">' + msg + '</label>');
    }
}

export default Form;