import Select from "../plugins/Select";

export function clear(form) {

    form.find('input:not([type=hidden]), textarea').val('');
    form.find('select').val('');
    Select.get(form).updateAll();

    if (form.find('.dm-uploader').length) {
        form.find('.dm-uploader').removeClass('dm-uploader--filled');
        form.find('.dm-uploader__item').remove();
    }
}