import Registry from './Registry';

class Select {
    constructor(obj) {
        this.obj = obj;
        if (typeof this.obj == 'string')
            this.obj = $(this.obj);
        this.select = this.obj.find('select:not([multiple])');
        this.multiSelect = this.obj.find('select[multiple]');
        this.onchanges = [];
        this.width = this.obj.width();
        this.i();
    }

    static get(selector) {

        var instance = Registry.get('Select', selector);
        if (instance)
            return instance;

        instance = new Select(selector);
        Registry.set('Select', selector, instance);

        return instance;
    }

    i() {
        if (this.obj.hasClass('initialized'))
            return;

        this.initSelect();
        this.initMultiSelect();
        this.addEvents();
        this.addClasses();
        return this;
    }

    updateAll() {
        var self = this;
        this.select.each(function () {
            self.update($(this));
        });
        this.multiSelect.each(function () {
            self.update($(this));
        })
    }

    update(select) {
        this.makeList(select, !!select.attr('multiple'));
        this.addClasses();
        this.checkSelectVal(select.parent().find('.select-list'));
        var name = select.attr('name');
        select.change();
    }

    initSelect() {
        if (!this.select.length)
            return;

        var self = this;

        self.select.each(function () {
            self.makeList($(this), false);
        })
    }

    initMultiSelect() {
        if (!this.multiSelect.length)
            return;

        var self = this;

        self.multiSelect.each(function () {
            self.makeList($(this), true)
        })
    }

    makeList(item, multi) {
        item.parent().find('.select__arrow').remove();
        var selectClass = item.data('simple') === '1' ? ' simple' : '';
        if (item.attr('select-class')) selectClass += " " + item.attr('select-class');
        var head = '<div class="select-head' + selectClass + '" ' +
            'data-text="' + item.parent().find('legend').text() + '"' + (item.attr('disabled') ? ' disabled' : '') + '>' +
            item.parent().find('legend').html() +
            '</div>',
            html = '<div class="select select--single" >',
            end = '</div></div></div>',
            self = this;

        if (item.data('input')) {
            head = '<div class="select__arrow"></div><input placeholder="' + item.parent().find('legend').text() + '" ' +
                'type="' + (item.data('input-type') ? item.data('input-type') : 'text') + '" ' +
                'class="select-head searchbar" ' +
                'data-text="' + item.parent().find('legend').text() + '" ' +
                'autocomplete="off" ' +
                (item.attr('disabled') ? 'disabled' : '') + '>';
            item.parent().addClass('arrowed');
        }

        if (multi)
            html = '<div class="select select--multi" >';

        if (item.data('searchbar')) {
            html += '<div class="select__searchbar"><input type="text" class="searchbar" placeholder="Введите.."></div>';
            end = '</div></div><div class="select__buttons"><div class="btn btn-small btn-apply">Применить</div><div class="btn btn-reset btn-small btn-apply">Сбросить фильтр</div></div></div>';
        }

        html += '<div class="select-list-wrap"><div class="select-list">';
        html = head + html;
        html += self.addHtml(item);
        html += end;

        item.parent().find('.select-head, .select').remove();
        $(html).insertAfter(item);

        self.countElements(item.siblings('.select'), false, true);
    };

    addHtml(item) {
        var childrens = item.children('option'),
            groups = item.children('optgroup'),
            html = '',
            self = this;

        childrens.each(function () {
            html += self.addOption($(this));
        });

        groups.each(function () {
            var checkbox = $(this).attr('data-checkbox') ? '<div class="checkmark"></div>' : '';
            var style = $(this).attr('data-checkbox') ? 'select-group--simple' : '';
            html += '<div class="select-group--head select__item ' + style + '">' + checkbox + $(this).attr("label") + '</div>';
            html += '<div class="select-group">';
            html += self.addHtml($(this));
            html += '</div>'
        });

        return html;
    }

    addOption(option) {
        var selected = option.attr('selected') ? 'selected' : '',
            url = option.data('url') ? 'data-url="' + option.data('url') + '" ' : '',
            checkmark = '<div class="checkmark__box"></div>',
            span = '';

        if (option.attr('data-check') === 'false') {
            checkmark = '';
            selected += ' select__item--nop';
        } else {
            selected += ' checkmark';
        }

        return '<div class="select__item ' + selected + '" ' +
            url +
            'data-value="' + option.val() + '"  >' +
            checkmark +
            span +
            option.html() +
            '</div>';
    }

    addEvents() {
        var selector = this.obj.attr('id'),
            self = this;

        $(document).on('click', '#' + selector + ' .select-head', function () {
            if ($(this).attr('disabled'))
                return;

            if (!$(this).hasClass('open')) {
                $(this).parents('form').find('.select, .arrowed, .select-head, .select__arrow').removeClass('open');
            }
            self.checkSelectPosition($(this));
            $(this).toggleClass('open');
            $(this).parent().find('.select__arrow').toggleClass('open');
            $(this).parent().find('.select').toggleClass('open');
            $(this).parents('.arrowed').eq(0).toggleClass('open');
        });

        $(document).on('click', '#' + selector + ' .select--single .select__item', function () {
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).parents('.form-group').find('select').val('').trigger('change');
            } else {
                $(this).parents('.select').find('.selected').removeClass('selected');
                $(this).addClass('selected');
                $(this).parents('.form-group').find('select').val($(this).data('value')).trigger('change');
            }

            $(this).parents('.form-group').find('.open').removeClass('open');
            self.countElements($(this).parents('.select'), true);

            for (var i of self.onchanges)
                self.onchanges[i]($(this), $(this).parents('.form-group').eq(0).children('select'));

            $(this).parents('.form-group').find('.select-head').removeClass('error');
            $(this).parents('.form-group').find('label.error').remove();
        });

        $(document).on('click', '#' + selector + ' .select--multi .select__item', function () {
            var curValues = [];

            if ($(this).attr('data-value') === "")
                $(this).parents('.select').find('.selected').removeClass('selected');

            if ($(this).hasClass('select-group--head')) {
                if ($(this).hasClass('selected'))
                    $(this).next().find('.select__item').removeClass('selected');
                else
                    $(this).next().find('.select__item').addClass('selected');
            }

            $(this).toggleClass('selected');

            $(this).parents('.select').find('.selected').each(function (index, element) {
                if (!$(element).hasClass('select-group--head')) {
                    curValues.push($(element).attr('data-value'));
                }
            });

            if ((curValues.indexOf('') + 1) && curValues.length > 1) {
                curValues.splice(curValues.indexOf(''), 1);
                $(this).parents('.select').find('[data-value=""]').removeClass('selected');
            }

            if ($(this).parent('.select-group').length) {
                if ($(this).parent('.select-group').children().length !== $(this).parent('.select-group').find('.selected').length)
                    $(this).parent().prev().removeClass('selected');
                else
                    $(this).parent().prev().addClass('selected');
            }

            $(this).parents('.form-group').find('select').val(curValues).change();
            self.countElements($(this).parents('.select'));

            $(this).parents('.form-group').find('.select-head').removeClass('error');
            $(this).parents('.form-group').find('label.error').remove();
        });

        $(document).on('click', '#' + selector + 'input:not(.searchbar):not(.select-head)', function () {
            $(this).parents('form').find('.select').removeClass('open');
            $(this).parents('form').find('.select-head').removeClass('open');
        });

        $(document).on('click', '#' + selector + ' input.searchbar', function () {
            $(this).parent().find('.select__item').show();
        });

        $(document).click(function (e) {
            var opened = $('body').find('#' + selector + ' .select-head.open');
            if (!opened.length)
                return;

            var target = $(e.target);
            if (!target.parents('#' + selector + ' .select').length &&
                !target.hasClass('open')) {
                opened.click();
            }
        });

        $(document).on('input', '#' + selector + ' .searchbar', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            if (!$(this).hasClass('open'))
                $(this).click();
            var list = $(this).parents('.form-group').find('.select__item'),
                val = $(this).val().replace('(', '\\(').replace(')', '\\)').replace('.', '\\.').replace('\\', '\\\\'),
                regex = new RegExp(val, 'gi'),
                listLength = 0;

            list.each(function () {
                if ($(this).text().match(regex)) {
                    listLength++;
                    $(this).show();
                } else
                    $(this).hide();
            });

            if (!listLength)
                $(this).parent().find('.select').hide();
            else
                $(this).parent().find('.select').show();
        });

        $(window).trigger('resize');

        this.obj.find('.select-list').each(function () {
            self.checkSelectVal($(this));
        })
    }

    checkSelectVal(item) {
        var selected = 0;
        item.find('.select__item').each(function () {
            if ($(this).hasClass('selected'))
                selected++;
        });

        if (!selected)
            item.parents('.form-group').find('select').val([]);
    }

    checkSelectPosition(item) {
        var screenHeight = $('body').innerHeight,
            group = item.parents('.form-group'),
            topOffset = group.offset().top + group.height();

        if (screenHeight - topOffset < group.find('.select').get(0).scrollHeight)
            group.find('.select').addClass('select--top');
        else
            group.find('.select').removeClass('select--top')
    }

    countElements(item, isSingle, isFirst) {
        var selected = item.find('.selected:not(.select-group--head)'),
            count = selected.length,
            head = item.parents('.form-group').find('.select-head'),
            substrLength = selected.find('.footnote').text().length,
            method = head.hasClass('searchbar') ? 'val' : 'text',
            text = '';

        if (isFirst) {
            if (!empty(selected.data('value'))) {
                if (count > 1)
                    head[method]('Выбрано ' + count);
                else {
                    if (head[0].tagName === 'INPUT')
                        head[method](selected.text());
                    else
                        head[method](selected.text().substr(0, selected.text().length - substrLength));
                }
            }
        } else if (isSingle) {
            if (count && selected.data('value') !== '') {
                text = selected.text().substr(0, selected.text().length - substrLength);
                // console.log(text);
                if (method === 'val') {
                    if (head.attr('type') === 'number' && text.match(/\D/g))
                        head.prop('value', '');
                    else
                        head[method](text);
                } else
                    head[method](text);
            } else {
                text = head.data('text');
                if (method === 'val') {
                    if (head.attr('type') === 'number' && text.match(/\D/g))
                        head.prop('value', '');
                    else
                        head[method](text);
                } else
                    head[method](text);
            }
        } else if (count == 1) {
            if (selected.data('value') === '') {
                text = head.data('text');
                if (method === 'val') {
                    if (head.attr('type') === 'number' && text.match(/\D/g))
                        head.prop('value', '');
                    else
                        head[method](text);
                } else
                    head[method](text);
            } else {
                text = selected.text().substr(0, selected.text().length - substrLength);
                if (method === 'val') {
                    if (head.attr('type') === 'number' && text.match(/\D/g))
                        head.prop('value', '');
                    else
                        head[method](text);
                } else
                    head[method](text);
            }
        } else if (count > 1) {
            head[method]('Выбрано ' + count);
        } else {
            var title = head.data('text');
            head[method](title);
            item.parent().find('[data-value=""]').addClass('selected');
        }
    }

    addClasses() {
        this.obj.addClass('initialized');
        this.select.addClass('initialized');
        this.multiSelect.addClass('initialized');
        this.select.find('.select__item').addClass('initialized');
        this.multiSelect.find('.select__item').addClass('initialized');
        this.obj.find('.none').removeClass('none');
    }

    onChange(func) {
        this.onchanges.push(func);
    }
}

export default Select;